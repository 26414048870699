<template>
  <v-container fluid>
    <v-row no-gutters class="fill-height">
      <v-col cols="3">
        <v-card flat :height="headerCardHeight" tile class="d-flex align-center justify-center">
          <v-card-title class="text-h4">
            <v-row no-gutters >
              <v-col cols="12">
                <v-icon large right color="primary lighten-3">
                  mdi-google-analytics
                </v-icon>
                Kiosk Analytics
              </v-col>
              <v-col cols="12">
                <v-chip class="text-h6 mt-3" outlined :color="getColor()" elevation="2">
                  <v-icon left class="mr-1">mdi-circle</v-icon>
                  {{ getKioskName }}
                </v-chip>
              </v-col>
            </v-row>
          </v-card-title>
        </v-card>
      </v-col>
      <v-col cols="9">
        <v-card flat :height="headerCardHeight" tile class="d-flex align-center justify-center">
          <v-card-text><v-row>
              <v-col cols="4" align-self="center">
                <v-card width="95%">
                  <v-card-title class="justify-center">
                    <span class="primary--text text--lighten-1 font-weight-regular">
                      Total User Sessions
                    </span>
                  </v-card-title>
                  <v-card-text class="d-flex justify-center">
                    <span v-if="kioskAnalytics.totalUserSessions > 0"
                      class="text-h5 font-weight-bold grey--text text--darken-2">
                      {{ kioskAnalytics.totalUserSessions }}
                    </span>
                    <span class="text-h5 font-weight-bold grey--text text--darken-2" v-else>
                      NA
                    </span>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="4" align-self="center">
                <v-card width="95%" class="">
                  <v-card-title class="justify-center">
                    <span class="primary--text text--lighten-1 font-weight-regular">
                      Total Questions Asked
                    </span>
                  </v-card-title>
                  <v-card-text class="d-flex justify-center">
                    <span v-if="analysisList.length > 0" class="text-h5 font-weight-bold grey--text text--darken-2">
                      {{ analysisList.length }}
                    </span>
                    <span class="text-h5 font-weight-bold grey--text text--darken-2" v-else>
                      NA
                    </span>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-card class="px-3 my-3" flat>
      <v-tabs v-model="tab">
        <v-tab>Graphs</v-tab>
        <v-tab>User Sessions</v-tab>
        <v-tab>Q&A Analytics</v-tab>
        <v-spacer></v-spacer>
        <div class="font-weight-bold mt-4 mr-6">Date: {{ displayDate }}</div>
        <v-card flat width="200">
          <v-select :items="duration" outlined v-model="selectedDuration" dense class="mt-2" ref="durationSelector">
            <template v-slot:item="data">
              <v-list-item v-if="data.item === 'Select Date'"
                @click="$store.commit('setSingleDatePicker', true); selectedDuration = 'Select Date'">
                Select Date
              </v-list-item>
              <v-list-item v-else-if="data.item === 'Select Week'"
                @click="$store.commit('setWeekDatePicker', true); selectedDuration = 'Select Week'">
                Select Week
              </v-list-item>
              <v-list-item v-else @click="durationSetter(data.item)">{{ data.item }}</v-list-item>
            </template>
          </v-select>
        </v-card>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item>
          <reports :userSessionList="userSessionList" :selectedDuration="selectedDuration"></reports>
        </v-tab-item>
        <v-tab-item>
          <userSession :loading="loading" :userSessionList="userSessionList">
          </userSession>
        </v-tab-item>
        <v-tab-item>
          <qnaAnalytics :analysisList="analysisList" :loading="loading">
          </qnaAnalytics>
        </v-tab-item>
        <!-- <v-tab-item>
                <reports></reports>                
              </v-tab-item> -->
      </v-tabs-items>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import userSession from "@/components/dashboard/admin/kioskAnalytics/userSession.vue";
import qnaAnalytics from "@/components/dashboard/admin/kioskAnalytics/qnaAnalytics.vue";
import reports from "@/components/dashboard/admin/kioskAnalytics/reports.vue";

export default {
  name: "kioskAnalytics",
  components: {
    userSession,
    qnaAnalytics,
    reports,
  },
  data() {
    return {
      selectedKiosk: null,
      tab: null,
      loading: false,
      headerCardHeight: window.innerHeight / 5,
      duration: ['Today', 'Yesterday', 'This Week', '2024', 'Select Date', 'Select Week', 'Fiscal Year', 'All'],
      selectedDuration: 'All',
      onlineStatus: '',
      date: new Date(),
      count: 0,
      totalQuestionsAsked: 0
    };
  },
  computed: {
    ...mapGetters([
      "allRegisteredKiosks",
      "kioskAnalytics",
      "getWindowDimensions",
      "displayDate",
      "selectKioskAnalytics"
    ]),
    kioskList() {
      let kioskName = [];
      this.allRegisteredKiosks.forEach((kiosk) =>
        kioskName.push(kiosk.data().name)
      );
      return kioskName;
    },
    getKioskName() {
      let temp;
      temp = this.allRegisteredKiosks.filter(kiosk => kiosk.data().applicationId === this.selectKioskAnalytics)
      return temp[0].data().name
    },
    analysisList() {
      let interactions = [];
      this.kioskAnalytics.userSessionAnalytics.forEach((event) => {
        console.log('Event: ', event)
        let qnaInteraction = event.sessionData.filter(session => session.actionType === 'QnA')
        console.log('qna response : ', qnaInteraction)
        qnaInteraction.forEach(qnaData => {
          interactions.push({
            kioskId: qnaData.applicationId,
            result: qnaData.response,
            timeStamp: qnaData.timeStamp,
            transcript: qnaData.action.trim(),
            id: this.count++
          });
        })
      });
      return interactions;
    },
    userSessionList() {
      let sessions = [];
      this.kioskAnalytics.userSessionAnalytics.forEach((event) => {
        let startTime = moment(event.startDate);
        let endTime = moment(event.endDate);
        sessions.push({
          id: event.applicationSessionId,
          kioskId: event.applicationId,
          userSessionDate: event.startDate,
          userSessionDuration: endTime.diff(startTime),
          userSessionStartTime: event.startDate,
          userSessionEndTime: event.endDate,
          userSessionLogs: event.sessionData,
        });
      });
      return sessions;
    },
  },
  watch: {
    selectedKiosk(newVal) {
      this.loadData(newVal);
    },
    getWindowDimensions(newVal) {
      this.headerCardHeight = newVal.height / 5;
    },
    selectedDuration(newVal) {
      if (newVal === 'Today') {
        this.$store.dispatch('getKioskAnalytics', {
          type: 'Today',
          start: null,
          end: null
        })
      } else if (newVal === 'Yesterday') {
        this.$store.dispatch('getKioskAnalytics', {
          type: 'Yesterday',
          start: null,
          end: null
        })
      }
      else if (newVal === 'This Week') {
        this.$store.dispatch('getKioskAnalytics', {
          type: 'This Week',
          start: null,
          end: null
        })
      } else if (newVal === '2024') {
        this.$store.dispatch('getKioskAnalytics', {
          type: '2024',
          start: null,
          end: null
        })
      } else if (newVal === 'Fiscal Year') {
        this.$store.commit("setFiscalYearPicker", true)
      } else if (newVal === null) {
        this.$store.dispatch('getKioskAnalytics', {
          type: 'Today',
          start: null,
          end: null
        })
      } else if (newVal === 'All') {
        this.$store.dispatch('getKioskAnalytics', {
          type: 'All',
          start: null,
          end: null
        })
      }
    }
  },
  methods: {
    syncData() {
      console.log('Current Selection: ', this.selectedDuration)
      this.date = new Date()
      this.$store.dispatch('getKioskAnalytics', {
        type: this.selectedDuration,
        start: null,
        end: null
      })
    },
    getAnalytics(item) {
      this.$store.commit("setSelectKioskAnalytics", item.data().applicationId)
      this.$store.dispatch('getKioskAnalytics', {
        type: 'Today',
        start: null,
        end: null
      })
      this.tab = 0
    },
    durationSetter(timeframe) {
      this.selectedDuration = timeframe
      this.$refs.durationSelector.isMenuActive = false;
    },
    getColor(status) {
      console.log('Which kiosk is selected: ', this.selectKioskAnalytics)
      let temp;
      temp = this.allRegisteredKiosks.filter(kiosk => kiosk.data().applicationId === this.selectKioskAnalytics)
      console.log('Is kiosk available?', temp)
      //return temp[0].data().name
      if (temp[0].data().onlineStatus === 'Available') {
        return 'success'
      } else {
        return 'error'
      }
      // if (status === "Unavailable") return "error";
      // if (status === "Busy") return "amber";
      // if (status === "Available") return "success";
    }
  },
  mounted() {
    if (this.allRegisteredKiosks.length === 0) {
      this.$store.dispatch("getAllRegisteredKiosks");
    }
  },
};
</script>